import { render, staticRenderFns } from "./list-selectV2.vue?vue&type=template&id=36c93176&scoped=true&"
import script from "./list-selectV2.vue?vue&type=script&lang=js&"
export * from "./list-selectV2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36c93176",
  null
  
)

export default component.exports